import { forwardRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ThemeMarginProps } from '@ui-v2/types/props';
import { buildResponsiveValues } from '@ui-v2/utils/buildResponsiveValues';
import { hexToRGBA } from '@ui-v2/utils/styleUtils';
import { buildMargin } from '@ui-v2/utils/themePropBuilders';
import Box from '../../Box/Box';
import Icon, { IconProps } from '../../Icon/Icon';
import Text from '../../Text/Text';
import {
  InputIconLeft,
  InputState,
  TRANSITION_SPEED,
  textColourMapper,
} from '../Input/SharedInputComponents';
import { getInputIconLeft, getInputState } from '../Input/utils';

export type SelectProps = Omit<
  React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > &
    Pick<ThemeMarginProps, 'mt' | 'mb'> & {
      errorMessage?: string;
      iconLeft?: IconProps['type'];
      iconRight?: IconProps['type'];
      label?: string;
      name: string;
      overriddenActiveState?: boolean;
      overriddenFocusState?: boolean;
    },
  'ref'
>;

export const StyledWrapper = styled('div')<ThemeMarginProps>(
  ({ theme, ...props }) => [
    css`
      position: relative;
    `,
    css(
      buildResponsiveValues({
        ...buildMargin(props),
      }),
    ),
  ],
);

export const StyledSelect = styled('select')<
  Omit<SelectProps, 'label' | 'arrowIcon'> & {
    hasIconLeft: boolean;
    state: InputState;
  }
>(({ hasIconLeft, state, theme }) => [
  css`
    display: block;
    width: 100%;
    padding: 16px;
    border: 1px solid ${theme.colours.border.default};
    border-radius: ${theme.shape.borderRadiusS}px;
    appearance: none;
    background: ${theme.colours.surface.default};
    font-family: ${theme.typography.body01.fontFamily};
    font-size: 16px;
    outline: none;
    transition:
      color ${TRANSITION_SPEED},
      border-color ${TRANSITION_SPEED},
      padding-right ${TRANSITION_SPEED};

    &:hover,
    &:active,
    &:focus {
      border-color: ${theme.colours.border.interactive};
    }
  `,
  state === 'error' &&
    css`
      border-color: ${theme.colours.border.critical};
    `,
  state === 'disabled' &&
    css`
      border-color: ${hexToRGBA(theme.colours.border.default, 0.5)};
      color: ${theme.colours.text.disabled};

      &:hover {
        border-color: ${hexToRGBA(theme.colours.border.default, 0.8)};
      }
    `,
  hasIconLeft &&
    css`
      padding-left: 48px;
    `,
]);

const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (props, forwardedRef) => {
    const {
      disabled,
      errorMessage,
      iconLeft,
      iconRight,
      id,
      label,
      mb,
      mt,
      name,
      ...rest
    } = props;

    const state = getInputState({
      disabled,
      errorMessage,
    });

    const renderedIconLeft = getInputIconLeft({
      iconLeft,
    });

    return (
      <StyledWrapper mb={mb} mt={mt}>
        {label && (
          <Text
            as="label"
            colour={textColourMapper[state]}
            htmlFor={id}
            variant="heading-5"
          >
            {label}
          </Text>
        )}
        <Box display="flex" position="relative">
          {renderedIconLeft && (
            <InputIconLeft state={state} type={renderedIconLeft} />
          )}
          <StyledSelect
            id={id}
            {...rest}
            disabled={disabled}
            hasIconLeft={Boolean(renderedIconLeft)}
            name={name}
            ref={forwardedRef}
            state={state}
          />

          <Box
            alignItems="center"
            display="flex"
            height="100%"
            pointerEvents="none"
            position="absolute"
            right={16}
            top={0}
          >
            <Icon size={24} type="chevronDownIcon" />
          </Box>
        </Box>
        {state === 'error' && (
          <Text as="div" colour="text.critical" variant="body-2">
            {errorMessage}
          </Text>
        )}
      </StyledWrapper>
    );
  },
);

Select.displayName = 'Select';

export default Select;
