import { useEffect, useState } from 'react';
import { useCombobox } from 'downshift';
import { useDebounce } from 'use-debounce';
import useCmsTranslation from '@utils/hooks/useCmsTranslation';
import useLocalStorage from '@utils/hooks/useLocalStorage';
import {
  SearchState,
  StationListLocationNode,
  StationListNode,
  useSearchWidget,
} from '@web/context/SearchWidgetContext';
import { filterAndSort } from '../helpers';

export type StationPickerListLabel = {
  value: string;
};

export type ComboBoxItem = StationListNode | StationPickerListLabel;

export const isComboBoxItemStationListNode = (
  item: ComboBoxItem | null | undefined,
): item is StationListNode => !!item && 'code' in item;

export const isComboBoxItemListLabel = (
  item: ComboBoxItem | null | undefined,
): item is StationPickerListLabel => !!item && !('code' in item);

export const isStationListLocationNode = (
  item: StationListNode,
): item is StationListLocationNode => 'location' in item;

export const useStationPicker = ({
  defaultItems,
  onSelection,
  pickerType,
  searchState,
  selection,
}: {
  defaultItems: StationListNode[];
  onSelection: (item: StationListNode | null | undefined) => void;
  pickerType: SearchState;
  searchState: SearchState;
  selection: StationListNode | null;
}) => {
  const { t } = useCmsTranslation();
  const { city, nearbyStations } = useSearchWidget();
  const [resultsState, setResultsState] = useState<
    'idle' | 'no-results-found' | 'showing-results' | 'showing-cta'
  >('idle');

  const [getRecentSearches, setRecentSearches] = useLocalStorage<
    Array<StationListNode>
  >('search-widget-recent-searches', []);

  const addToRecentSearches = (item: StationListNode | null | undefined) => {
    const previous = getRecentSearches();

    if (item && !previous.find((x) => x.code === item.code)) {
      setRecentSearches([item, ...previous].slice(0, 3));
    }
  };

  const [items, setItems] = useState<Array<ComboBoxItem>>([]);

  const closeMenu = () => {
    setResultsState('idle');
    setItems([]);
  };

  const {
    getInputProps,
    getItemProps,
    getMenuProps,
    highlightedIndex,
    inputValue,
    selectedItem,
    setHighlightedIndex,
    setInputValue,
  } = useCombobox<ComboBoxItem>({
    items,
    selectedItem: selection,
    itemToString: (item) => item?.value ?? '',
    onSelectedItemChange: ({ selectedItem }) => {
      if (!isComboBoxItemStationListNode(selectedItem)) {
        return;
      }
      // Storing the selected value for "recent searches"
      addToRecentSearches(selectedItem);

      // Close the menu
      closeMenu();

      onSelection(selectedItem);
    },
  });

  const [debouncedValue] = useDebounce(inputValue, 300);

  const setCTAMenu = () => {
    setResultsState('showing-cta');
    const recentSearches = getRecentSearches();

    const updatedRecentSearches = recentSearches
      .map((recentSearch) => {
        const matchingItem = defaultItems.find((item) => {
          return recentSearch.code === item.code;
        });

        return matchingItem;
      })
      .filter((item): item is StationListNode => Boolean(item));

    setItems([
      ...(updatedRecentSearches.length > 0
        ? [
            {
              value: t('recent_searches', 'Recent searches'),
            },
          ]
        : []),
      ...updatedRecentSearches,
      ...(nearbyStations.length > 0 &&
      pickerType === 'selecting-take-off-station'
        ? [{ value: t('nearby_stations', 'Nearby stations') }]
        : []),
      ...(pickerType === 'selecting-take-off-station' ? nearbyStations : []),
    ]);
  };

  useEffect(() => {
    /**
     * Sort items and open the menu if:
     * There's a value
     * That value is not the selected value
     * Otherwise close it
     */
    if (debouncedValue && inputValue && inputValue !== selectedItem?.value) {
      const filteredItems = filterAndSort(defaultItems, debouncedValue);

      if (filteredItems.length > 0) {
        setItems(filteredItems);
        setHighlightedIndex(0);
        setResultsState('showing-results');
      } else {
        setResultsState('no-results-found');
      }
    } else if (inputValue === '') {
      /**
       * Unsure what we want to do here.
       * In this scenario, the user has started a search and then
       * removed the search query, do we want to show the CTA menu again?
         setResultsState('showing-cta');
       */
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);
  /**
   * The menu is open if:
   * The input has a value
   * That value is not the currently selected item
   */
  const isOpen =
    items.length > 0 || resultsState !== 'idle' || searchState === pickerType;

  const isShowingResults =
    isOpen && items.length > 0 && resultsState === 'showing-results';

  const isShowingNoResultsMessage =
    isOpen && resultsState === 'no-results-found';

  const isShowingCTAMenu = isOpen && resultsState === 'showing-cta';

  return {
    getInputProps,
    getItemProps,
    getMenuProps,
    highlightedIndex,
    setInputValue,
    setResultsState,
    selectedItem,
    closeMenu,
    inputValue,
    isOpen,
    setCTAMenu,
    isShowingResults,
    isShowingNoResultsMessage,
    isShowingCTAMenu,
    items,
    city,
  };
};
