import { OfferFragment } from '@codegen/gatewayUtils';
import { Station, StationListNode } from '../SearchWidgetContext';

export const arrangeStationsToListNodes = (
  items: Station[],
): StationListNode[] =>
  items.flatMap((item) =>
    item.subItems.flatMap((subItem) => [
      {
        ...subItem,
        country: item.value,
        parentCode: null,
      },
      ...subItem.subItems.flatMap((subSubItem) => ({
        ...subSubItem,
        parentCode: subItem.code,
        country: item.value,
      })),
    ]),
  );

export const findStationListNodeBySearchQuery = ({
  items,
  queryCodes,
}: {
  items: StationListNode[];
  queryCodes: string[] | undefined;
}): StationListNode | null => {
  if (!queryCodes || queryCodes.length === 0) {
    return null;
  }

  // Single value, find that value
  if (queryCodes.length === 1) {
    const [queryCode] = queryCodes;

    return items.find((x) => x.code === queryCode) ?? null;
  }

  // If a parent item includes every code in the query, we return the parent item
  const parentItem = items.find((item) => {
    return (
      item.subItems.length > 0 &&
      item.subItems.every((subItem) => queryCodes.includes(subItem.code))
    );
  });

  if (parentItem) {
    return parentItem;
  }

  // No parent match, try to match an item with any query code
  return items.find((x) => queryCodes.includes(x.code)) ?? null;
};

export const getStationCodes = (station: StationListNode) => {
  // If this is a parent item, return a map of the child codes
  if (station.subItems.length > 0) {
    return station.subItems.map((x) => x.code);
  }

  // Return items code as an array
  return [station.code];
};

export const getCheapestOffer = (
  type: 'outbound' | 'inbound',
  offers?: OfferFragment[] | null,
) => {
  return offers?.reduce<OfferFragment | null>((acc, curr) => {
    if (!acc) {
      return curr;
    }

    if (
      type === 'outbound' &&
      curr.outboundPricePerPerson < acc.outboundPricePerPerson
    ) {
      return curr;
    }

    if (
      type === 'inbound' &&
      curr.homeboundPricePerPerson < acc.homeboundPricePerPerson
    ) {
      return curr;
    }

    return acc;
  }, null);
};
