import styled from '@emotion/styled';
import { PassengerRuleFragment } from '@codegen/cmsUtils';
import Counter from '@ui-v2/components/Counter/Counter';
import Box from '@ui-v2/core/Box/Box';
import Select from '@ui-v2/core/Forms/Select/Select';
import Text from '@ui-v2/core/Text/Text';
import { isNumber } from '@utils/helperUtils';
import useCmsTranslation from '@utils/hooks/useCmsTranslation';

type Props = {
  isDecrementDisabled: boolean;
  isIncrementDisabled: boolean;
  onChangePaxAge: ({
    index,
    type,
    value,
  }: {
    index: number;
    type: string;
    value: number;
  }) => void;
  onDecrement: () => void;
  onIncrement: () => void;
  rule: PassengerRuleFragment;
  subtitle: string;
  title: string;
  value: number[];
};

const StyledSubtitle = styled.div`
  margin-top: -4px;
  font-size: 14px;
  font-weight: 600;
`;

const PassengerCounterItem = ({
  isDecrementDisabled,
  isIncrementDisabled,
  onChangePaxAge,
  onDecrement,
  onIncrement,
  rule,
  subtitle,
  title,
  value,
}: Props) => {
  const hasAgeInput = isNumber(rule.minAge) && isNumber(rule.maxAge);
  const { t } = useCmsTranslation();

  const paxTypeTranslationMapper: Record<'child' | 'infant', string> = {
    child: t('c', 'Child').toLocaleLowerCase(),
    infant: t('infant', 'Infant').toLocaleLowerCase(),
  };

  return (
    <Box pb={hasAgeInput && value.length > 0 ? 8 : 0}>
      <Box
        borderBottom="subdued"
        display="flex"
        justifyContent="space-between"
        px={16}
        py={8}
      >
        <Box>
          <Text as="div" variant="body-1">
            {title}
          </Text>
          <StyledSubtitle>{subtitle}</StyledSubtitle>
        </Box>
        <Box alignItems="center" display="flex" gap={8}>
          <Counter
            canDecrement={!isDecrementDisabled}
            canIncrement={!isIncrementDisabled}
            decrement={onDecrement}
            id={rule.passengerType}
            increment={onIncrement}
            value={value.length}
          />
        </Box>
      </Box>
      {hasAgeInput &&
        value.map((age, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box key={index} mt={8} px={16}>
            <Text as="div" variant="body-2">
              {t(
                'Age of {{paxType}} {{paxTypeNumber}}',
                `Age of ${rule.passengerType} ${index + 1}`,
                {
                  paxType:
                    paxTypeTranslationMapper[
                      rule.passengerType as 'child' | 'infant'
                    ],
                  paxTypeNumber: index + 1,
                },
              )}
            </Text>
            <Select
              id={`${rule.passengerType}-${index + 1}`}
              name={`passenger-${rule.passengerType}-${index}-age-selection`}
              onChange={(e) =>
                onChangePaxAge({
                  index,
                  type: rule.passengerType,
                  value: Number(e.target.value),
                })
              }
              value={age}
            >
              <option disabled>{t('Select age', 'Select age')}</option>
              {[...Array((rule.maxAge ?? 0) - (rule.minAge ?? 0) + 1)].map(
                (_key, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <option key={index}>{(rule.minAge ?? 0) + index}</option>
                ),
              )}
            </Select>
          </Box>
        ))}
    </Box>
  );
};

export default PassengerCounterItem;
