const useLocalStorage = <T>(
  key: string,
  defaultValue: T,
): [() => T, (value: T) => void] => {
  const getItem = () => {
    let currentValue;

    try {
      currentValue = JSON.parse(
        localStorage.getItem(key) || String(defaultValue),
      );
    } catch (error) {
      currentValue = defaultValue;
    }

    return currentValue;
  };

  const setItem = (value: T) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  return [getItem, setItem];
};

export default useLocalStorage;
