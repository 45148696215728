import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Button from '@ui-v2/core/Button/Button';

// Note: Generally extending a ui-core button is illegal
// We are breaking the rules for this single instance since the search widget button is a very isolated exception
export const SearchWidgetButton = styled(Button)(
  ({ theme }) => css`
    padding: 14px ${theme.spacings[64]}px;
    border-radius: ${theme.shape.searchWidgetBorderRadius};
    background: ${theme.colours.components.search.interactive.default};
    box-shadow: none;
    color: ${theme.colours.components.search.text.on.interactive};

    &:hover {
      background: ${theme.colours.components.search.interactive.hover};
      color: ${theme.colours.components.search.text.on.interactive};
    }

    &:active,
    &:focus {
      background: ${theme.colours.components.search.interactive.pressed};
      color: ${theme.colours.components.search.text.on.interactive};
    }

    .button-loader-wrapper {
      background: ${theme.colours.interactive.primary.default};
    }

    .button-loader {
      color: ${theme.colours.icons.on.primary.default};
    }
  `,
);
