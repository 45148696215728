import { StationListNode } from '@web/context/SearchWidgetContext';

const normalizeValue = (value: string) =>
  value
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

const includesInputValue = (value: string, inputValue: string) =>
  normalizeValue(value).includes(normalizeValue(inputValue));

const isExactMatch = (value: string, inputValue: string): boolean =>
  normalizeValue(value) === normalizeValue(inputValue);

const indexRank = (a: string, b: string, inputValue: string) => {
  const indexInValueA = a.indexOf(inputValue);
  const indexInValueB = b.indexOf(inputValue);

  if (indexInValueA === indexInValueB) {
    return null;
  }

  if (indexInValueA === -1) {
    return 1;
  }

  if (indexInValueB === -1) {
    return -1;
  }

  return indexInValueA - indexInValueB;
};

const compareRank = (a: StationListNode, b: StationListNode) => {
  // If one of the ranks is 0, it's treated as lower priority
  if (a.rank === 0 && b.rank !== 0) {
    return 1;
  }

  if (b.rank === 0 && a.rank !== 0) {
    return -1;
  }

  // Sorts in descending order for non-zero ranks
  return b.rank - a.rank;
};

const filterItems = (
  items: StationListNode[],
  inputValue: string,
): StationListNode[] =>
  items.filter(
    (item) =>
      includesInputValue(item.value, inputValue) ||
      includesInputValue(item.code, inputValue) ||
      includesInputValue(item.country, inputValue),
  );

const sortItems = (
  items: StationListNode[],
  inputValue: string,
): StationListNode[] => {
  return [...items].sort((a, b) => {
    const aValueNormalized = normalizeValue(a.value);
    const bValueNormalized = normalizeValue(b.value);
    const aCodeNormalized = normalizeValue(a.code);
    const bCodeNormalized = normalizeValue(b.code);
    const inputValueNormalized = normalizeValue(inputValue);

    // Exact matches always have the highest priority
    const exactMatchA =
      isExactMatch(aCodeNormalized, inputValueNormalized) ||
      isExactMatch(aValueNormalized, inputValueNormalized);

    const exactMatchB =
      isExactMatch(bCodeNormalized, inputValueNormalized) ||
      isExactMatch(bValueNormalized, inputValueNormalized);

    if (exactMatchA && !exactMatchB) {
      return -1;
    }

    if (!exactMatchA && exactMatchB) {
      return 1;
    }

    // Next priority is the inputValue index in the station value
    const valueIndexRank = indexRank(
      aValueNormalized,
      bValueNormalized,
      inputValueNormalized,
    );

    if (valueIndexRank !== null) {
      return valueIndexRank;
    }

    // Next priority is the inputValue index in the station code
    const codeIndexRank = indexRank(
      aCodeNormalized,
      bCodeNormalized,
      inputValueNormalized,
    );

    if (codeIndexRank !== null) {
      return codeIndexRank;
    }

    // Next priority is the rank we get from the API
    const rankComparison = compareRank(a, b);

    if (rankComparison !== 0) {
      return rankComparison;
    }

    // The final priority is the inputValue index in the station country
    const countryIndexRank = indexRank(
      normalizeValue(a.country),
      normalizeValue(b.country),
      inputValueNormalized,
    );

    if (countryIndexRank !== null) {
      return countryIndexRank;
    }

    return 0;
  });
};

export const filterAndSort = (
  items: StationListNode[],
  inputValue: string,
): StationListNode[] => sortItems(filterItems(items, inputValue), inputValue);

export const getItemLevel = (
  item: StationListNode,
  items: StationListNode[],
): 1 | 2 => {
  if (item.parentCode === null) {
    return 1;
  }

  // If the list of results includes this item's parent code, it is a level 2 item
  if (items.find((x) => x.code === item.parentCode)) {
    return 2;
  }

  return 1;
};
