import {
  PassengerRuleFragment,
  PassengerRulesFragment,
} from '@codegen/cmsUtils';
import { Passengers } from '@web/context/SearchWidgetContext';

export const isDecrementPassengerDisabled = ({
  numberOfSelectedPassengersOfType,
  passengerRules,
  passengers,
  paxRule,
}: {
  maxPassengersAllowed: number;
  numberOfSelectedPassengersOfType: number;
  numberOfTotalSelectedPassengers: number;
  passengerRules: PassengerRulesFragment;
  passengers: Passengers;
  paxRule: PassengerRuleFragment;
}) => {
  // If the count has reached the min count rule
  if (numberOfSelectedPassengersOfType <= (paxRule.minCount ?? 1)) {
    return true;
  }

  const ward = passengerRules.rules.find(
    (x) => x.guardianPassengerType === paxRule.passengerType,
  );

  if (ward) {
    /**
     * If this passenger type has a ward, we can not decrement
     * the number of passengers below the "guardians per passenger value" of the wards
     * Example: We have two adults and two infants selected, we can not decrease the adults to 1
     * because it does not fulfill the guardian rule of the infants
     */
    const minNumberOfGuardians =
      (ward.guardianMaxPerPassengerType ?? 0) *
      (passengers[ward.passengerType]?.length ?? 0);

    if (numberOfSelectedPassengersOfType <= minNumberOfGuardians) {
      return true;
    }
  }

  return false;
};

export const isIncrementPassengerDisabled = ({
  maxPassengersAllowed,
  numberOfSelectedPassengersOfType,
  numberOfTotalSelectedPassengers,
  passengers,
  paxRule,
}: {
  maxPassengersAllowed: number;
  numberOfSelectedPassengersOfType: number;
  numberOfTotalSelectedPassengers: number;
  passengers: Passengers;
  paxRule: PassengerRuleFragment;
}) => {
  // Number of total selected passengers is highen than the maximum passengers allowed
  if (numberOfTotalSelectedPassengers >= maxPassengersAllowed) {
    return true;
  }

  const numberOfSelectedGuardians = paxRule.guardianPassengerType
    ? passengers[paxRule.guardianPassengerType]?.length
    : null;

  // Passenger type has guardians configured and the total is equal or higher than the allowed number per guardian
  if (
    numberOfSelectedGuardians &&
    Number.isInteger(paxRule.guardianMaxPerPassengerType) &&
    paxRule.guardianMaxPerPassengerType
  ) {
    return (
      numberOfSelectedPassengersOfType >=
      numberOfSelectedGuardians * paxRule.guardianMaxPerPassengerType
    );
  }

  // None of the rules apply, increment is not disabled
  return false;
};
