import { useMemo } from 'react';
import { PassengerRulesFragment } from '@codegen/cmsUtils';
import { constructSearchQueryParams } from '@web/utils/search/searchWidgetUtils';
import usePartnerRouter from './usePartnerRouter';

const useSearchQueryParams = (
  passengerRules: PassengerRulesFragment | null,
  defaultOrigins?: string[],
  defaultDestinations?: string[],
) => {
  const {
    query: {
      destinations: queryDestination = defaultDestinations,
      origins: queryOrigins = defaultOrigins,
      ...restQuery
    },
  } = usePartnerRouter();

  const {
    carrierCodes,
    currency,
    departureDate,
    destinations,
    isOneWay,
    origins,
    paxTypeAges,
    residency,
    returnDate,
  } = useMemo(
    () =>
      constructSearchQueryParams(
        {
          ...restQuery,
          origins: Array.isArray(queryOrigins)
            ? queryOrigins
            : queryOrigins?.split(','),
          destinations: Array.isArray(queryDestination)
            ? queryDestination
            : queryDestination?.split(','),
        },
        passengerRules,
      ),
    [passengerRules, queryDestination, queryOrigins, restQuery],
  );

  const numberOfSelectedPassengers = useMemo(
    () =>
      Object.keys(paxTypeAges).reduce((prev, curr) => {
        return prev + (paxTypeAges[curr]?.length ?? 0);
      }, 0),
    [paxTypeAges],
  );

  return {
    origins,
    destinations,
    departureDate,
    returnDate,
    residency,
    currency,
    isOneWay,
    paxTypeAges,
    carrierCodes,
    numberOfSelectedPassengers,
  };
};

export default useSearchQueryParams;
