import { Theme } from '@emotion/react';
import { TabButtonVariant } from '@ui-v2/types/buttons';
import { hexToRGBA } from '@ui-v2/utils/styleUtils';

type TabButtonVariantStyleConfig = {
  '&:focus': {
    background?: string;
    color: string;
  };
  '&:hover': {
    background?: string;
    color: string;
  };
  color: string;
};

export const buildTabButtonVariant = (
  variant: TabButtonVariant,
  theme: Theme,
  isActive: boolean,
): TabButtonVariantStyleConfig => {
  switch (variant) {
    default: {
      return {
        color: isActive
          ? theme.colours.brand.accent
          : theme.colours.text.subdued,

        '&:hover': {
          background: hexToRGBA(theme.colours.brand.primary, 0.05),
          color: isActive
            ? theme.colours.brand.accent
            : theme.colours.text.default,
        },
        '&:focus': {
          background: hexToRGBA(theme.colours.brand.primary, 0.08),
          color: theme.colours.brand.primary,
        },
      };
    }
  }
};
